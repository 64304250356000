<template>
	<div class="row contentblock">
		<div class="columns column6 col-image">
			<div v-if="video" class="video-wrapper">
				<iframe
					:src="`${video}?controls=0&wmode=opaque&showinfo=0&rel=0&autoplay=1&playsinline=1&loop=1&mute=1&playlist=${getVideoID()}`"
					frameborder="0"
					allow="autoplay; fullscreen; picture-in-picture"
					allowfullscreen
					style="position: absolute; top: -75px; left: 0; width: 100%; height: 100%; z-index: 2"
				/>
			</div>
			<div v-else>
				<picture>
					<source :srcset="imageWebp" type="image/webp" />
					<source :srcset="image" />
					<img :src="image" :alt="imageAlt" loading="lazy" />
				</picture>
			</div>
		</div>
		<div class="columns column6 col-content">
			<div class="contentblock-content">
				<h3>{{ header }}</h3>
				<div v-parse-links v-html="content" />
				<slot name="extra-content" />
			</div>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	image: { type: String, default: '' },
	imageWebp: { type: String, default: '' },
	imageAlt: { type: String, default: '' },
	video: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});

const getVideoID = () => {
	return props.video.split('/').at(-1);
};
</script>

<style lang="scss" scoped>
.contentblock {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0;
	margin: 20px auto;
	position: relative;

	&:nth-child(odd) {
		flex-direction: row-reverse;

		.col-image {
			left: 0;
			right: auto;
		}
	}

	.columns:first-child {
		padding: 0;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.col-content {
		padding: 0;
		position: relative;
		z-index: 2;
		width: 40%;
	}

	.col-image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 59%;
		overflow: hidden;
	}

	.contentblock-content {
		padding: 100px 75px;
		background: #fff;
	}
}

.video-wrapper {
	height: 140%;
	width: 110%;
	margin: 0 0 0 -5%;
	padding: 56.25% 0 0;
	position: absolute;
	inset: 0;
}

@media (max-width: 1080px) {
	.contentblock {
		.col-image {
			width: 100%;
			position: relative;
		}

		.video-wrapper {
			height: 100%;
			width: 100%;
			margin: 0;
			padding: 0;
			position: relative;
			inset: auto;
			min-height: 400px;
			overflow: hidden;

			iframe {
				top: 0 !important;
				width: 140% !important;
				height: 120% !important;
				margin-left: -20% !important;
			}
		}

		.col-content {
			padding: 0;
			width: 100%;
			margin: 10px auto;
		}

		.contentblock-content {
			padding: 50px;
		}
	}
}

@media (max-width: 580px) {
	.contentblock {
		margin-bottom: 25px;

		.video-wrapper {
			min-height: 260px;

			iframe {
				width: 130% !important;
				height: 100% !important;
				margin-left: -15% !important;
			}
		}

		.contentblock-content {
			padding: 35px;
		}
	}
}
</style>
